import { DialogActions, DialogContent } from '@mui/material'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import SaveToSAcceptance from '../SaveToSAcceptance/SaveToSAcceptance'
import ToSAcceptance from '../ToSAcceptance/ToSAcceptance'

const messages = defineMessages({
    tosAcceptanceDialogTitle: {
        defaultMessage: 'Our Privacy Policy and Terms of Service',
        description: 'Our Privacy Policy and Terms of Service'
    },
    tosAcceptanceSaveButton: {
        defaultMessage: 'Agree and get started',
        description: 'Accept the Privacy Policy and Terms of Service'
    }
})

export interface ToSAcceptanceModalProps extends JSX.IntrinsicAttributes {
    open: boolean
    onAcceptToS: (success: boolean) => Promise<void>
}

export const ToSAcceptanceModal: React.FC<ToSAcceptanceModalProps> = ({
    open,
    onAcceptToS
}) => {
    const { formatMessage } = useIntl()
    const [toSAccepted, setToSAccepted] = useState<boolean>(false)

    return (
        <LeafDialog
            fullWidth
            open={open}
            hideCloseButton
            disableEscapeKeyDown
            dialogTitle={formatMessage(messages.tosAcceptanceDialogTitle)}
            sx={{
                zIndex: 9999
            }}
        >
            <DialogContent>
                <ToSAcceptance
                    toSAccepted={toSAccepted}
                    onToSAccepted={setToSAccepted}
                />
            </DialogContent>

            <DialogActions>
                <SaveToSAcceptance
                    color="primary"
                    disabled={!toSAccepted}
                    onSavedToSAcceptance={onAcceptToS}
                    size="large"
                    variant="contained"
                >
                    <CoreTypography customVariant="buttonLarge">
                        {formatMessage(messages.tosAcceptanceSaveButton)}
                    </CoreTypography>
                </SaveToSAcceptance>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(ToSAcceptanceModal)
