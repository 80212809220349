import { Checkbox, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafPrivacyPolicyLink,
    LeafToSLink
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, ReactNode } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

const messages = defineMessages({
    tosAcceptanceDialogBody: {
        defaultMessage:
            'Please agree to the Privacy Policy and Terms of Service before proceeding with Thrive.',
        description:
            'Please agree to the Privacy Policy and Terms of Service before proceeding with Thrive.'
    },
    agreeToToS: {
        defaultMessage:
            'I agree to the <privacyPolicy>Privacy Policy</privacyPolicy> and the <termsOfService>Terms of Service</termsOfService>',
        description: 'Message to accept the Privacy Policy and Terms of Service'
    }
})

export interface ToSAcceptanceProps extends JSX.IntrinsicAttributes {
    toSAccepted: boolean
    onToSAccepted: (toSAccepted: boolean) => void
}

export const ToSAcceptance: React.FC<ToSAcceptanceProps> = ({
    toSAccepted,
    onToSAccepted
}) => {
    const { formatMessage } = useIntl()

    return (
        <Stack>
            <CoreTypography pb={1} variant="body1">
                {formatMessage(messages.tosAcceptanceDialogBody)}
            </CoreTypography>

            <Stack direction="row" alignItems="center">
                <Checkbox
                    id="tnc-acceptance"
                    value={toSAccepted}
                    data-testid="tnc-acceptance"
                    aria-label={formatMessage(messages.tosAcceptanceDialogBody)}
                    onChange={() => onToSAccepted(!toSAccepted)}
                />
                <label htmlFor="tnc-acceptance">
                    <CoreTypography variant="body2">
                        {formatMessage(messages.agreeToToS, {
                            privacyPolicy: (chunk: ReactNode) => (
                                <LeafPrivacyPolicyLink hideIcon={true}>
                                    {chunk}
                                </LeafPrivacyPolicyLink>
                            ),
                            termsOfService: (chunk: ReactNode) => (
                                <LeafToSLink hideIcon={true}>
                                    {chunk}
                                </LeafToSLink>
                            )
                        })}
                    </CoreTypography>
                </label>
            </Stack>
            <CoreTypography variant="caption" sx={{ pt: 1 }}>
                <FormattedMessage
                    defaultMessage={`After providing the necessary consent, click "Agree and get started" to access Thrive’s services.`}
                    description="description of what clicking accept grants"
                />
            </CoreTypography>
        </Stack>
    )
}

export default memo(ToSAcceptance)
